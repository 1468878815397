body.login {
  background: #fff;
}

.user-login-5 .form-group.has-error {
  border-bottom: none !important;
}

.user-login-5 .has-error input {
  border-bottom: 2px solid #ed6b75 !important;
}

.user-login-5 .login-container > .login-content .login-form {
  color: #a4aab2;
  font-size: 13px;
}

.user-login-5 .login-container > .login-content .login-form .form-control {
  width: 100%;
  padding: 10px 0;
  border: none #a0a9b4;
  border-bottom: 1px solid;
  color: #868e97;
  font-size: 14px;
  margin-bottom: 0;
}

.user-login-5 .login-container > .login-content > .login-form .form-group {
  margin-bottom: 30px;
}

.user-login-5 .login-container > .login-content .login-form .has-error .mt-checkbox {
  margin-bottom: 0 !important;
}

.user-login-5 .login-container > .login-content .login-spacer-one-line {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

.user-login-5 .alert {
  margin-top: 0;
}

